<template>
  <div>
    <bread-layout :items="breadcrumbLinks" @add="add" add_button></bread-layout>
    <div class="row">
      <AlertsPayment :nextPayments="getAuthUser?.client?.next_payment" />
    </div>
    <div class="row">
      <notes-timeline
        v-for="(notes, key, index) in notes_day"
        :key="index"
        :title="key"
        :notes="notes"
        v-slot="{ noteId }"
      >
        <li
          class="dropdown-item"
          @click.prevent="edit(noteId)"
          data-target="#modalAdd"
          data-toggle="modal"
          role="button"
        >
          <i class="fa fa-edit text-secondary mr-2"></i> Editar
        </li>
        <li
          class="dropdown-item"
          @click.prevent="destroy(noteId)"
          role="button"
        >
          <i class="fa fa-trash text-danger mr-2"></i> Eliminar
        </li>
      </notes-timeline>
    </div>

    <!-- Modals -->
    <modal-add-edit
      :form="form"
      :method_post="method_post"
      :title_modal="title_modal"
      :submitForm="submitForm"
      :v="v$"
    ></modal-add-edit>
    <!-- End Modals -->
  </div>
</template>

<script>
import AlertsPayment from "@/views/profile/AlertsPayment.vue";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue-demi";
import BreadLayout from "../../layouts/BreadLayout.vue";
import NotesTimeline from "./NotesTimeline.vue";
import useNotesForm from "@/composables/notes/useNotesForm.js";
import modalAddEdit from "./NotesAddEdit.vue";
export default {
  components: { BreadLayout, NotesTimeline, modalAddEdit, AlertsPayment },
  setup() {
    onMounted(() => {
      window.scrollTo( 0, 0 );
    });
    const breadcrumbLinks = ref([
      {
        text: "Notas",
        path: "/notas",
      },
    ]);

    // form
    const {
      title_modal,
      method_post,
      form,
      add,
      edit,
      destroy,
      submitForm,
      v$,
      loading,
      getNotes,
      data: notes_day,
    } = useNotesForm();
    getNotes();

    const store = useStore();

    return {
      getAuthUser: computed(() => store.state.User.user),
      breadcrumbLinks,
      loading,
      notes_day,
      title_modal,
      method_post,
      form,
      add,
      edit,
      destroy,
      submitForm,
      v$,
    };
  },
};
</script>

<style>
</style>
