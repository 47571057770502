import peticionHttp from "../peticionHttp";
const service = "api";

export const getNotesListService = async () => {
    const response = await peticionHttp.get(`${service}/notes`);
    return response;
};

export const addNoteService = async (payload) => {
    const response = await peticionHttp.post(`${service}/notes`, payload);
    return response;
};

export const getNoteService = async (id) => {
    const response = await peticionHttp.get(`${service}/notes/get/${id}`);
    return response;
};

export const editNoteService = async (payload) => {
    const response = await peticionHttp.put(`${service}/notes`, payload);
    return response;
};

export const deleteNoteService = async (id) => {
    const response = await peticionHttp.delete(`${service}/notes/${id}`);
    return response;
};
