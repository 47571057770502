<template>
    <teleport to="#modal-wrapper">
        <c-modal
            id="modalAdd"
            route_name="/api/notes/update"
            :form="form"
            :title_modal="title_modal"
            :method_post="method_post"
            :btnSubmit="formHasErrors.length > 0"
            @onSubmit="submitForm()"
        >
            <template #modalBody>
                <div class="col-md-12">
                    <div class="form-group" :class="{ error: v.date.$errors.length }">
                        <label for="name">Fecha y hora *</label>
                        <input
                            type="datetime-local"
                            name="date"
                            id="date"
                            v-model="form.date"
                            class="form-control"
                            required
                        />
                        <error-msg :errors="v.date.$errors"></error-msg>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group" :class="{ error: v.text.$errors.length }">
                        <label for="name">Nota *</label>
                        <textarea
                            type="text"
                            name="text"
                            id="text"
                            v-model="form.text"
                            class="form-control"
                            cols="30"
                            rows="5"
                            required
                        ></textarea>
                        <error-msg :errors="v.text.$errors"></error-msg>
                    </div>
                </div>
            </template>
        </c-modal>
    </teleport>
</template>

<script>
import { computed } from '@vue/runtime-core'
import CModal from '@/components/modals/CModal.vue'
import ErrorMsg from '@/components/modals/ErrorMsg.vue'
export default {
    components: {
        CModal,
        ErrorMsg,
    },
    props: {
        form: Object,
        method_post: Boolean,
        title_modal: String,
        submitForm: Function,
        v: Object,
    },
    setup(props) {

        const formHasErrors = computed(() => {
            return props.v.$silentErrors
        })

        return {
            formHasErrors,
        }
    },
}
</script>
