<template>
    <div class="col-xl-4 col-lg-12">
        <div class="card">
            <div class="card-header border-0 pb-0">
                <h4 class="card-title">{{ dateFormat(title) }}</h4>
            </div>
            <div class="card-body">
                <div id="DZ_W_TimeLine" class="widget-timeline dz-scroll height370">
                    <ul class="timeline">
                        <li v-for="(note, index) in notes" :key="index">
                            <note-message-card :note="note"></note-message-card>
                            <ul class="dropdown-menu">
                                <slot :noteId="note.id"></slot>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useFormat from '@/composables/format/useFormat.js'
import NoteMessageCard from './NoteMessageCard.vue'
export default {
    components: {
        NoteMessageCard
    },
    props: {
        title: {
            type: String,
        },
        notes: {
            type: Array
        }
    },
    setup() {
        const { dateFormat } = useFormat()
        return {
            dateFormat
        }
    }
}
</script>

<style scoped>
</style>
