import { ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import NotesAddRules from "@/rules/NotesAddRules.js";
import { getDateYyyyMmDd } from '@/utils/dates.js'
import {
    getNoteService,
    addNoteService,
    editNoteService,
    deleteNoteService,
} from "@/services/notes/notesServices.js";
import useGetNotes from "./useGetNotes.js";
export default function useNotesForm() {
    /**
     *   Propiedades de la ventana modal
     */

    const title_modal = ref("");
    const method_post = ref(true);

    /**
     *   Propiedades del formulario
     */

    const form = reactive({
        id: "",
        date: "",
        text: "",
    });

    /**
     *   Manejador para añadir un registro
     */

    const add = () => {
        $("#modalAdd").modal("toggle");
        cleanForm();
        title_modal.value = "Añadir nota";
        method_post.value = true;
    };

    /**
     *   Manejador para editar un registro
     */

    const edit = async (id) => {
        cleanForm();
        title_modal.value = "Editar nota";
        method_post.value = false;

        const response = await getNoteService(id);

        if (response.status === 200) {
            Object.assign(form, response.data);
            form.date = response.data.date.split(' ').join('T')
        } else {
            Swal.fire(
                "Error!",
                "No encontramos lo que buscaba!.",
                "error"
            ).then(() => {
                $("#modalAdd").modal("toggle");
            });
        }
    };

    /**
     *   Manejador para eliminar un registro
     */

    const destroy = (id) => {
        Swal.fire({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, no podras revertir esto.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await deleteNoteService(id);
                if (response.status === 200) {
                    Swal.fire("Hecho", response.message, "success").then(() => {
                        getNotes();
                    });
                } else {
                    Swal.fire("Error!", response.message, "error");
                }
            }
        });
    };

    /**
     *   Limpia el formulario y resetea las reglas de validación
     */


    const cleanForm = () => {
        Object.assign(form, {
            id: "",
            date: getDateYyyyMmDd('extended'),
            text: "",
        });
        /**
         * Resetea el formulario
         */
        v$.value.$reset();
    };

    /**
     *   Se solicitan e imoplementan las reglas de validación
     */

    const { rules } = NotesAddRules();

    const v$ = useVuelidate(rules, form, { $autoDirty: true });

    /**
     *   Se consume el servicio para obtener los registros desde el hook create
     */

    const { loading, getNotes, data } = useGetNotes();

    /**
     *   Manejador del evento submit en formulario
     */

    const submitForm = async () => {
        const isFormCorrect = await v$.value.$validate();
        if (!isFormCorrect) return;

        const response = method_post.value
            ? await addNoteService(form)
            : await editNoteService({ note_id: form.id, ...form });
        if (response.status === 200) {
            getNotes();
            Swal.fire(
                "Hecho",
                response.message,
                "success"
            ).then(() => {
                $("#modalAdd").modal("toggle");
            });
        } else {
            Swal.fire(
                "Error!",
                response.message,
                "error"
            ).then(() => {
                $("#modalAdd").modal("toggle");
            });
        }
    };

    return {
        // modal
        title_modal,
        method_post,
        // form
        form,
        add,
        edit,
        destroy,
        // validations
        submitForm,
        v$,
        // getData
        loading,
        getNotes,
        data,
    };
}
