<template>
    <div class="timeline-badge c-timeline-badge" :style="`background-color: ${note.color};`"></div>
    <a
        class="timeline-panel text-muted c-timeline-panel"
        :style="`background: ${note.color}; border-color:${note.color};`"
        href="#"
        data-toggle="dropdown"
    >
        <span>{{ note.date.slice(10) }}</span>
        <h6 class="mb-0">{{ note.text }}</h6>
    </a>
</template>

<script>
export default {
    props: {
        note: Object
    }
}
</script>

<style scoped>
.dz-scroll {
    overflow-y: scroll;
}
/* background scrollbar */
.dz-scroll::-webkit-scrollbar {
    width: 5px;
    background: rgb(253, 253, 254);
}
/* scrollbard */
.dz-scroll::-webkit-scrollbar-thumb {
    background: rgb(223, 223, 232, 0.4);
    border-radius: 5px;
}
/* scroll hover */
.dz-scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(223, 223, 232, 0.5);
}

.c-timeline-badge {
    border-color: #fff;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
}

.c-timeline-panel {
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
}
</style>
